import React, { useEffect, useRef, useState } from 'react'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// GraphQL
import { getGraphTowns } from '../../../graphQL'

// Components
import CityPanel from '../../molecules/CityPanel'
import MapMarker from '../../molecules/MapMarker'

// Hooks
import useWindowSize from '../../../hooks/windowSize'

// Styles
import { mapImg, mapZoom, mapIcon, mapWrapper } from './styles.module.scss'

type Coordinates = {
  x: number
  y: number
}

const MapPanel: React.FC = () => {
  const startTowns = getGraphTowns()
    .filter((town) => town.name !== 'Unknown')
    .sort((a, b) => {
      if (a.name < b.name) return -1
      return 1
    })
    .map((town) => {
      return { ...town, marker: false }
    })

  const baseWidth = 250
  const baseHeight = 240

  const { width } = useWindowSize()

  const [zoom, setZoom] = useState(3)
  const [towns, setTowns] = useState(startTowns)
  const [dragging, setDragging] = useState(false)
  const [posIni, setPosIni] = useState<Coordinates>({ x: 0, y: 0 })
  const [margin, setMargin] = useState<Coordinates>({ x: 0, y: 0 })

  const mapRef = useRef<HTMLDivElement | null>(null)

  const getMaxSlider = () => {
    if (mapRef?.current?.clientWidth) {
      return {
        maxW: mapRef?.current?.clientWidth - zoom * baseWidth,
        maxH: mapRef?.current?.clientHeight - zoom * baseHeight,
      }
    }
    return { maxW: 0, maxH: 0 }
  }

  const correctMargin = (coordinates: Coordinates) => {
    if (mapRef?.current?.clientWidth) {
      let { x, y } = coordinates
      let { maxW, maxH } = getMaxSlider()
      if (mapRef?.current?.clientWidth > zoom * baseWidth) {
        x = (mapRef?.current?.clientWidth - zoom * baseWidth) * 0.5
      } else {
        if (x > 0) x = 0
        if (x < maxW) x = maxW
      }
      if (mapRef?.current?.clientHeight > zoom * baseHeight) {
        y = (mapRef?.current?.clientHeight - zoom * baseHeight) * 0.5
      } else {
        if (y > 0) y = 0
        if (y < maxH) y = maxH
      }
      setMargin({ x, y })
    }
  }

  const moveByCoordinates = (x: number, y: number) => {
    if (mapRef?.current?.clientWidth) {
      correctMargin({
        x: mapRef?.current?.clientWidth * 0.5 - x * (zoom * 250),
        y: mapRef?.current?.clientHeight * 0.5 - y * (zoom * 240),
      })
    }
  }

  const dragStart = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.TouchEvent<HTMLDivElement>
  ) => {
    if (mapRef?.current?.clientWidth) {
      if (
        mapRef?.current?.clientWidth < zoom * baseWidth ||
        mapRef?.current?.clientHeight < zoom * baseHeight
      ) {
        let e = event || window.event
        e.preventDefault()
        const x =
          event.type === 'touchstart'
            ? (e as React.TouchEvent<HTMLDivElement>).touches[0].clientX
            : (e as React.MouseEvent<HTMLDivElement, MouseEvent>).clientX
        const y =
          event.type === 'touchstart'
            ? (e as React.TouchEvent<HTMLDivElement>).touches[0].clientY
            : (e as React.MouseEvent<HTMLDivElement, MouseEvent>).clientY
        setDragging(true)
        setPosIni({ x, y })
      }
    }
  }

  const dragAction = (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.TouchEvent<HTMLDivElement>
  ) => {
    if (dragging) {
      let e = event || window.event
      e.preventDefault()
      e.stopPropagation()
      const x =
        event.type === 'touchmove'
          ? (e as React.TouchEvent<HTMLDivElement>).touches[0].clientX
          : (e as React.MouseEvent<HTMLDivElement, MouseEvent>).clientX
      const y =
        event.type === 'touchmove'
          ? (e as React.TouchEvent<HTMLDivElement>).touches[0].clientY
          : (e as React.MouseEvent<HTMLDivElement, MouseEvent>).clientY

      correctMargin({ x: margin.x - posIni.x + x, y: margin.y - posIni.y + y })
      setPosIni({ x, y })
    }
  }

  const dragEnd = () => {
    setDragging(false)
  }

  const setMarker = (townName: string) => {
    const newTowns = towns.map((town) => {
      if (town.name === townName) return { ...town, marker: true }
      else return { ...town, marker: false }
    })
    setTowns(newTowns)
  }

  useEffect(() => {
    moveByCoordinates(0.5, 0.5)
  }, [])

  useEffect(() => {
    correctMargin({ x: margin.x, y: margin.y })
  }, [zoom, width])

  return (
    <>
      <div className={mapWrapper} ref={mapRef}>
        <div className={mapZoom}>
          <FontAwesomeIcon
            icon={faPlus}
            onClick={() => {
              if (mapRef?.current?.clientWidth && zoom < 8) {
                const ratio = (zoom + 1) / zoom
                correctMargin({
                  x:
                    mapRef?.current?.clientWidth * 0.5 * (1 - ratio) +
                    ratio * margin.x,
                  y:
                    mapRef?.current?.clientHeight * 0.5 * (1 - ratio) +
                    ratio * margin.y,
                })
                setZoom(zoom + 1)
              }
            }}
            className={mapIcon}
          />
          <FontAwesomeIcon
            icon={faMinus}
            onClick={() => {
              if (mapRef?.current?.clientWidth && zoom > 3) {
                const ratio = (zoom - 1) / zoom
                correctMargin({
                  x:
                    mapRef?.current?.clientWidth * 0.5 * (1 - ratio) +
                    ratio * margin.x,
                  y:
                    mapRef?.current?.clientHeight * 0.5 * (1 - ratio) +
                    ratio * margin.y,
                })
                setZoom(zoom - 1)
              }
            }}
            className={mapIcon}
          />
        </div>
        <div
          onMouseDown={(e) => dragStart(e)}
          onMouseMove={(e) => dragAction(e)}
          onMouseUp={() => dragEnd()}
          onMouseOut={() => dragEnd()}
          onTouchStart={(e) => dragStart(e)}
          onTouchMove={(e) => dragAction(e)}
          onTouchEnd={() => dragEnd()}
          style={{
            width: `${zoom * 250}px`,
            marginLeft: `${margin.x}px`,
            marginTop: `${margin.y}px`,
            position: 'relative',
          }}
        >
          <img
            src="https://dilancovak.com/.img/map/MapV1.png"
            className={mapImg}
          />
          {towns.map((town) => (
            <MapMarker
              town={town}
              key={`Marker_${town.name}`}
              setMarker={setMarker}
            />
          ))}
        </div>
      </div>
      <CityPanel
        towns={towns}
        moveByCoordinates={moveByCoordinates}
        setMarker={setMarker}
      />
    </>
  )
}

export default MapPanel
